import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import TopNav from '../component/TopNav'; 
import HeroCourseDetail from '../component/HeroCourseDetail'; 
import CourseChapterList from '../component/CourseChapterList'; 
import { Tabs, Tab } from "@blueprintjs/core";
import CourseSectionMarkdown from '../component/CourseSectionMarkdown'; 

@withRouter
@inject("store")
@observer
export default class CourseDetail extends Component
{
    state = {"info":false,"show_tab":"all"};
    
    async componentDidMount()
    {
        const id = this.props.match.params.id; 
        if( id > 0 )
        {
            const info = await this.props.store.get_course_detail( id );
            if( info ) this.setState({info});
            // console.log( this.state );  

            // window.setTimeout( ()=>this.checkLastPlay(id) , 3*1000 );
        }
    }

    checkLastPlay( id )
    {
        const last_section_id = parseInt(window.localStorage.getItem("WM_last_section_"+id));
        if( last_section_id > 0 )
        {
            window.localStorage.removeItem("WM_last_section_"+id);
            
            if( window.confirm("是否接上次进度播放？") )
            {
                window.location = "/section/show/"+last_section_id;
            }
        }
    }

    firstSection( index )
    {
        let sections = [];
        Object.values(index).forEach( item => 
        {
            if( item.sections )
            {
                Object.values(item.sections).sort( (a , b) => a.order - b.order ).forEach( item2 =>
                {
                    sections.push( item2 ); 
                })
            }
        });
        return sections[0] ? sections[0] : false ;
    }

    render()
    {
        const index_div = this.state.info && this.state.info.index ? <CourseChapterList data={this.state.info.index} color={this.state.info.course?.color||"blue"}/>: null;

        const desp_div = this.state.info && this.state.info.course && this.state.info.course.desp ? <CourseSectionMarkdown data={this.state.info.course.desp} /> : null;

    const all_div = <>{desp_div}<div className="std-hr"/>{index_div}</>;
        
        
        const main = <div>
            <TopNav/>
            { this.state.info && this.state.info.course && <HeroCourseDetail data={this.state.info.course} first={this.firstSection(this.state.info.index)} is_paid={this.state.info.is_paid} /> }

            <div className="container course-detail-box">
                <Tabs className="tabs" selectedTabId={this.state.show_tab} onChange={(newtabid)=>this.setState({"show_tab":newtabid})}>
                    <Tab id="all" title="详情" panel={all_div} />
                    <Tab id="index" title="目录" panel={index_div} />
                </Tabs> 
            </div>
            
            
        </div>;

        return <DocumentTitle title={this.props.store.appname}>{main}</DocumentTitle>
    }
}