import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter, Link } from 'react-router-dom';

@withRouter
@inject("store")
@observer
export default class Footer extends Component
{
    // constructor(props) 
    // {
    //     super(props);
    // }
    
    // componentDidMount()
    // {
    //    // 
    // }

    // componentDidUpdate(prevProps)
    // {
    //     if (this.props.data !== prevProps.data) 
    //     {
           
    //     }
    // }
    
    render()
    {
        return <div className="container  footer-box">&copy; stack.ftqq.com 版权所有 · <Link to="/agreement.post">使用协议</Link> · <a href="https://support.qq.com/products/130458" target="feedback">反馈和投诉</a> · <a href="http://www.beian.miit.gov.cn/" target="_blank">京ICP备13034689号</a> 
        </div>;
    }
}