import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter,NavLink } from 'react-router-dom';

@withRouter
@inject("store")
@observer
export default class TopMenu extends Component
{
    render()
    {
        return <ul className="top-nav-menu">
            { this.props.store.menu.map( item =>
                {
                    return <li key={item.link+item.text}><NavLink to={item.link}>{item.text}</NavLink></li>
            } ) }
        {/* <li key="dingyueke"><a href="https://ding.ftqq.com?fr=mian" target="_blank">订阅课</a></li> */}
        {/* <li key="shizhanke"><a href="https://url.163.com/ftqq" target="_blank">实战营</a></li> */}
        </ul>;
    }
}