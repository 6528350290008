import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Button, AnchorButton } from '@blueprintjs/core';
import BuyButton from '../component/BuyButton'; 
import * as sd from 'simple-duration';

@withRouter
@inject("store")
@observer
export default class HeroCourseDetail extends Component
{
    // constructor(props) 
    // {
    //     super(props);
    // }
    
    // componentDidMount()
    // {
    //    // 
    // }

    // componentDidUpdate(prevProps)
    // {
    //     if (this.props.data !== prevProps.data) 
    //     {
           
    //     }
    // }
    gotoFirst()
    {
        if( this.props.first )
        {
            window.location = '/section/show/'+this.props.first.id;
        }
    }

    goCheck()
    {
        this.props.history.push("/process/"+this.props.data.id);
    }

    buyIt()
    {
        this.props.store.to_buy_course( this.props.data.id );
    }


    render()
    {
        const course = this.props.data;
        if( !course ) return null;

        return <div className={"ft-notop ft-bg-"+course.color} >
        <div className="course-box">
            
            <div className="content-box">
                <div className="container">
                    <div className="left">
                        <div className="catebutton">
                            <Button icon="double-chevron-right" text={course.cate.text} large={true} minimal={true} className="white" onClick={()=>this.props.history.push(course.cate.link)}/>
                        </div>
                        <div className="title">{course.title}</div> 
                        <div className="subtitle">{course.subtitle}</div>
                        {course.wanmian_days > 0 && <div className="wanmian-info">
                            <div>共{course.section_count}节</div>    
                            <div>累计时长 {sd.stringify(parseInt(course.duration), "s")}</div>
                            <div>{course.wanmian_days}天内学完免费</div>
                        </div>}
                        <div className="desp">
                        {course.intro}
                        </div>
                        <div className="actionbar">
                            { this.props.store.subcribe_mode ? <Button text="开始学习" large={true} minimal={true} icon="play" onClick={()=>this.gotoFirst()} className="outline white"/> :  this.props.is_paid && parseInt( this.props.is_paid ) == 1 ?
                            <>
                                <Button text="开始学习" large={true} minimal={true} icon="play" onClick={()=>this.gotoFirst()} className="outline white"/>  

                                <Button text="查看进度" large={true} minimal={true} icon="timeline-bar-chart" onClick={()=>this.goCheck()} className="outline white"/> 

                                <Button text="下载资料" large={true} minimal={true} icon="box" className="outline white" onClick={()=>this.props.history.push("/share/"+course.id)}/>  

                            </>
                             :  
                             <>
                                
                                { course.wanmian_days > 0 ? <>
                                    <BuyButton text={"预付学习 ¥" + (course.price_cent/100).toFixed(2)} course_id={course.id}/>

                                    <AnchorButton text="学完免费" large={true} minimal={true} icon="info-sign" className="outline white" target="_blank" href="/rules.post"/>

                                    <AnchorButton text="订阅" large={true} minimal={true} icon="bookmark" className="outline white" target="_blank" href="/subscribe"/>

                                    </>: <>

                                    {/* <Button text={"单独购买 ¥" + (course.price_cent/100).toFixed(2)} large={true} minimal={true} icon="cube-add" onClick={()=>this.buyIt()} className="outline white"/> */}


                                    <AnchorButton text="订阅" large={true} minimal={true} icon="bookmark" className="outline white" target="_blank" href="/subscribe"/>
                                    </>}
                                
                                

                            
                            </> }
                            
                           
                            
                        </div>
                    </div>
                    <div className="right">
                        <div className="author-info ft-bg-dark">
                            <div className="avatar flexcenter">
                                <img src={course.author.headimgurl} alt=""/>
                            </div>
                            <div className="author-title">
                            主讲人 · {course.author.nickname}
                            </div>
                            <div className="author-desp">
                            {course.author_intro}
                            </div>
    
    
                        </div>
                    </div>
                    
                </div>
    
            </div>
            {/* <div className="actionbar cic-bg-dark">
                <div className="container">
                    操作栏
                </div>
            </div> */}
            
        </div>
    </div>;
    }
}