import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Button, Menu, MenuItem, Popover, Position } from "@blueprintjs/core";

@withRouter
@inject("store")
@observer
export default class SmallMenu extends Component
{
       
    render()
    {
        const exampleMenu = (
            <Menu>
                { this.props.store.menu.map( item =>
                    {
                        return <MenuItem key={item.text+'-'+item.link} text={item.text} onClick={()=>this.props.history.push(item.link)} />
                } ) }
                { this.props.store.user ? 
                
                <>
                {
                
                <MenuItem text={"方糖币和充值"} icon="dollar" onClick={()=>this.props.history.push("/recharge")} />
                
                /* 
                <MenuItem text={"互动课领取"} icon="airplane" onClick={()=>this.props.history.push("/cic")} /> */}
                {/* <MenuItem text={"实战课"} icon="airplane" onClick={()=>window.location='https://url.163.com/ftqq'} /> */}
                <MenuItem text={"退出"+this.props.store.user.nickname} icon="user" onClick={()=>this.props.history.push("/logout")} />
                </>
                : <MenuItem text="微信登入" icon="user" onClick={()=>this.props.authpop()}/>}

                
            </Menu>
        );

        return <div style={{marginRight:"10px"}}><Popover content={exampleMenu} position={Position.BOTTOM}>
        <Button rightIcon="caret-down" className="outline" large={true} minimal={true} text="菜单"/>
        </Popover></div>;
    }
}