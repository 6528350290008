import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import Footer from '../component/Footer'; 
import TopNav from '../component/TopNav'; 
import { AnchorButton } from "@blueprintjs/core";

@withRouter
@inject("store")
@observer
export default class RoadMap2023 extends Component
{
    // constructor(props) 
    // {
    //     super(props);
    // }
    
    componentDidMount()
    {
       Intense(window.document.querySelector(".road"));
    }

    render()
    {
        const main = <div><TopNav/>
        <div className="page-box white-box">
        <div className="ft-post-page ">
            <img src="/road.jpg" alt="路线图" className="road"/>
            <center className="mt-2">
            <AnchorButton large={true} href="https://github.com/easychen/stack-roadmap" icon="link" target="_blank" text="下载路线图PDF和源文件"/>
            <p className="mt-2 the-gray">◎ 本站目前只包含路线图的部分内容，正在逐渐增加中 ◎</p>
            </center>
        </div>
        </div>
        <Footer/>
        </div>;
        return <DocumentTitle title={this.props.store.appname}>{main}</DocumentTitle>;
    }
}