import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import * as sd from 'simple-duration';

@withRouter
@inject("store")
@observer
export default class CourseSectionItem extends Component
{
    // constructor(props) 
    // {
    //     super(props);
    // }
    
    // componentDidMount()
    // {
    //    // 
    // }

    // componentDidUpdate(prevProps)
    // {
    //     if (this.props.data !== prevProps.data) 
    //     {
           
    //     }
    // }
    go( url )
    {
        // this.props.history.push( url );
        window.location= url;
    }
    
    render()
    {
        const section  = this.props.data;
        if( !section ) return null;
        const  classstr = this.props.isActive ? "active" : "";  

    return <li className={"section-item "+classstr} onClick={()=>this.go('/section/show/'+section.id)}><div className="item-title">{section.title} {section.is_free > 0 && <span className={"badge ft-bg-"+this.props.color||"blue"}>可试学</span>}</div> {section.media_type == 1 ? <span className={"badge ft-bg-"+this.props.color||"blue"}>图文</span> : ( parseInt(section.duration) > 0 ?<span className="duration">{sd.stringify(parseInt(section.duration), "s").replace('m','分').replace('s','秒').replace('h','小时')}</span> :  <span className={"badge ft-bg-"+this.props.color||"blue"}>即将上线</span>) }</li>;
    }
}