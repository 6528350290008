import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Button } from "@blueprintjs/core";
import Select from 'react-select';
@withRouter
@inject("store")
@observer
export default class SubscribeLine extends Component
{
    // constructor(props) 
    // {
    //     super(props);
    // }
    
    // componentDidMount()
    // {
    //    // 
    // }

    // componentDidUpdate(prevProps)
    // {
    //     if (this.props.data !== prevProps.data) 
    //     {
           
    //     }
    // }
    submit(value)
    {
        if( this.props.onSubmit ) this.props.onSubmit(value);
    }

    buycard()
    {
        if( this.props.onBuyCard ) this.props.onBuyCard();
    }
    
    render()
    {
        
        const coin = this.props.coin || "0";
        let value = this.props.store[this.props.field] || "0";
        let options = this.props.options;
        options.forEach( item =>
            {
                if( this.props.first_year_subscribed )
                {
                    item.label = item.name.replace( '订阅' , '续订' );
                }
                else
                {
                    item.label = item.name
                }
            }  );
        let selectedOption = options.filter( item => item.value == value )[0];
        let key = 1;
        const prefix = Date.now();
        const others_year_price = 499; 
        const first_year_price = this.props.first_year_subscribed ?  others_year_price : 799; 
        const price = first_year_price + (value-1)*others_year_price;
        const saved = (value-1)*(first_year_price-others_year_price);

        return <div className="subscribe-line" >
            <div className="left">
            <Select
            placeholder={this.props.placeholder||"请选择"}
            value={selectedOption}
            isMulti={this.props.isMulti}
            onChange={(e)=>
            {
                this.props.store[this.props.field] = e.value;
            } }
            options={this.props.options||[]}
        />
            </div>
            <div className="center">
        共 {price} 方糖币 { saved > 1 ? <span>节省{saved}</span> : null}
            </div>
            <div className="right">
                <Button text={(coin >= price ? "支付" : "余额不足")+"(现有方糖币"+coin+"枚)"} large={true} intent="primary" disabled={coin < price} onClick={()=>this.submit(value)}/>
                <Button text="充值方糖币" large={true} onClick={()=>this.buycard()} />
                
            </div>
        </div>;
    }
}