import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Button } from '@blueprintjs/core';
import CourseSectionItem from '../component/CourseSectionItem'; 


@withRouter
@inject("store")
@observer
export default class CourseChapterItem extends Component
{
    state = {"extend":true };
    
    // componentDidMount()
    // {
    //    // 
    // }

    // componentDidUpdate(prevProps)
    // {
    //     if (this.props.data !== prevProps.data) 
    //     {
           
    //     }
    // }
    
    render()
    {
        const chapter = this.props.data;
        if( !chapter ) return null;
        
        const icon = this.state.extend ? "minus" : "plus";
        return <div className="chapter">
        <div className="title">
            <div className="text">
                {chapter.title}
            </div>
            <div className="action">
                <Button icon={icon} large={true} minimal={true} onClick={()=>this.setState({"extend":!this.state.extend})}/>
            </div>
            </div>
        { this.state.extend && <ul className="section-list">
            { chapter.sections && Object.values(chapter.sections) && Object.values(chapter.sections).sort( (a , b) => a.order - b.order ).map( item => <CourseSectionItem data={item} key={item.id} isActive={ item.id == this.props.active } color={this.props.color||"blue"}/>  )}
           
        </ul> }
        
    </div>;
    }
}