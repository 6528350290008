import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import Footer from '../component/Footer'; 
import TopNav from '../component/TopNav'; 
import TextLine from '../component/TextLine'; 
import SubmitLine from '../component/SubmitLine'; 
import { FormGroup, AnchorButton } from "@blueprintjs/core";
import { toast, isWechat } from '../util/Function';

@withRouter
@inject("store")
@observer
export default class SubdeerCourse extends Component
{
    // constructor(props) 
    // {
    //     super(props);
    // }
    
    // componentDidMount()
    // {
    //    // 
    // }
    async getCourse()
    {
        if( !this.props.store.the_subdeer_code )
        {
            toast("请输入提取码");
            return false;
        }

        const ret = await this.props.store.subdeer_get_course( this.props.store.the_subdeer_code );
        if( ret && ret.course )
        {
            toast(`课程《${ret.course.title}》领取成功`);
            this.props.history.push('/course/'+ret.course.id);
        }
    }

    render()
    {
        const main = <div><TopNav />
        <div className="page-box">
            <div className="ft-post-page">
                <h2 className="rs-title">SubDeer众筹课领取</h2>
                <div className="whitebox small-margin">
                    <TextLine field="the_subdeer_code" label="SubDeer课程提取码" required={true} placeholder="课程提取码/课程密码"/>
                    <SubmitLine label="领取" onSubmit={()=>this.getCourse()} right={<AnchorButton icon="share" href="https://subdeer.cn" target="_blank" large={true}>Subdeer.cn</AnchorButton>}/>
                </div>  
            </div>
                      
        </div>
        <Footer/>
        </div>;
        return <DocumentTitle title={this.props.store.appname}>{main}</DocumentTitle>;
    }
}